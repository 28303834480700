<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>出票管理</el-breadcrumb-item>
			<el-breadcrumb-item>{{charName}}</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!--查询-->
			<el-form ref="searchFormRef"
			         :model="searchForm"
			         label-width="100px"
			         class="search-form">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="票据包号：">
							<el-input v-model="searchForm.paperPagNo"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="票据种类：">
							<el-select v-model="searchForm.ticketClass" placeholder="请选择">
								<el-option label="银票" value="AC01"></el-option>
								<el-option label="商票" value="AC02"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item :label="isAcceptor + ':'">
							<el-input v-model="searchForm.acceptor"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="出票日期：" class="special-form-item">
							<el-date-picker
											v-model="searchForm.ticketDateStart"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
							<span class="data-gap">至</span>
							<el-date-picker
											v-model="searchForm.ticketDateEnd"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="票据包金额：" class="special-form-item">
							<el-input v-model="searchForm.tradeAmtMin" class="special-input"></el-input>
							<span class="data-gap">至</span>
							<el-input v-model="searchForm.tradeAmtMax" class="special-input"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="到期日期：" class="special-form-item">
							<el-date-picker
											v-model="searchForm.dueDateStart"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
							<span class="data-gap">至</span>
							<el-date-picker
											v-model="searchForm.dueDateEnd"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-form-item class="btn-form-item">
						<el-button type="primary" @click="getTableList">查询</el-button>
						<el-button @click="handleReset">重置</el-button>
					</el-form-item>
				</el-row>
			</el-form>
			<!--列表-->
			<el-table :data="tableList" border>
				<el-table-column prop="paperBagNo" label="票据包号" min-width="220px"></el-table-column>
				<el-table-column prop="ticketClass" label="票据种类" min-width="100px"></el-table-column>
				<el-table-column :prop="isAcceptor === '承兑人'? 'acceptor' : 'taker'" :label="isAcceptor" min-width="150px"></el-table-column>
				<el-table-column prop="tradeAmt" label="票据包金额（元）" min-width="150px"></el-table-column>
				<el-table-column prop="ticketDate" label="出票日期" min-width="120px"></el-table-column>
				<el-table-column prop="dueDate" label="到期日" min-width="120px"></el-table-column>
				<el-table-column prop="ticketStatus" label="票据状态" min-width="100px"></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="320px">
					<template v-slot="scope">
						<el-button type="primary" size="mini" @click="handleFaceView(scope.row)">
							票面预览
						</el-button>
						<el-button type="primary" size="mini" @click="handleFaceDownload(scope.row)">
							票面下载
						</el-button>
						<el-button type="primary" size="mini" @click="handleTableBtn(scope.row)">{{charName}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页区域-->
			<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchForm.pageNum"
							:page-sizes="[10, 20, 30, 40]"
							:page-size="searchForm.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total"
							class="mt20">
			</el-pagination>
			<!--短信验证-->
			<el-dialog
							title="短信验证"
							:visible.sync="messageDialogVisible"
							:close-on-click-modal="false"
							width="50%"
							@close="messageDialogClosed">
				<el-form :model="messageForm"
				         :rules="messageFormRules"
				         ref="messageFormRef"
				         class="message-form">
					<el-form-item label="操作员手机号" prop="operatorMobile">
						{{mobile}}
					</el-form-item>
					<el-form-item label="短信验证码" prop="verifyCode">
						<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
						<DelayButton :text="text" :isDisabled="isDisabled" @delayButtonClick="delayButtonClick"/>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
			</el-dialog>
			<!--成功-->
			<el-dialog :visible.sync="successDialogVisible"
			           width="40%"
			           :close-on-click-modal="false">
				<div class="agree-dialog">
					<img src="~@/assets/img/ticket/cg.png" alt="">
					<h3>{{copyText}}</h3>
					<div class="copy-code">
						操作编号：<h4>{{copyCode}}</h4>
					</div>
					<el-button type="primary" @click="handleCopy">点击复制</el-button>
				</div>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
  import DelayButton from "@/views/Ticket/childrenCpn/SpecialButton";
  export default {
    name: "BillOutOther",
	  props: ['placeholder'],
	  components: {
      DelayButton
	  },
	  mounted(){
      // 获取列表数据
		  this.getTableList()
	  },
	  data(){
      return {
        text: '',
        isDisabled: null,
        // 搜索表单数据
        searchForm: {
          // pageType: '', // 页面类型
          paperPagNo: '', //票据包号
          ticketClass: '', //票据种类(AC01：银票、AC02：商票)
          acceptor: '', //承兑人
          ticketDateStart: '', //出票日期 起始日期
          ticketDateEnd: '',   //出票日期 结束日期
          tradeAmtMin: '',     //票据包金额 min
          tradeAmtMax: '',     //票据包金额 max
          dueDateStart: '',    //到期日期 起始日期
          dueDateEnd: '',      //到期日期 结束日期
          pageSize: 10,  //当前页面数据量
          pageNum: 1,    //当前页码
        },
        total: 0, // 总数
        tableList: [], //列表数据
        ticketId: '', //登记申请id -- 用于验证短信
        handlerTypeCode: '', // 操作类型code -- 用于验证短信
        handlerNodeCode: '', // 节点状态code -- 用于验证短信
        mobile: '', //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        successDialogVisible: false, // 控制收票成功弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
	  },
	  watch: {
      // 监听具体页面重新获取 数据
      placeholder(){
        this.getTableList()
      }
	  },
	  computed: {
      // 列表按钮
      charName(){
        let placeholder = this.placeholder;
        switch (placeholder) {
						case "2":
						return '提示承兑申请';
						break;
						case "3":
            return '提示承兑撤回';
            break;
          case "4":
            return '提示收票申请';
            break;
          case "5":
            return '提示收票撤回';
            break;
						
        }
      },
		  isAcceptor(){
        let placeholder = this.placeholder;
        switch (placeholder) {
          case "2":
            return '承兑人';
            break;
          case "3":
            return '承兑人';
            break;
          case "4":
            return '收票人';
            break;
          case "5":
            return '收票人';
            break;

        }
		  }
	  },
	  methods: {
      // 获取列表数据
      async getTableList(){
        const params = {
          pageType: this.placeholder,
	        ...this.searchForm
        }
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/ticket/manage/page",{params})
        if(res.code !== 200) return this.$message.error(res.msg)
	      this.tableList = res.data.records;
        this.total = res.data.total
      },
		  // 监听重置按钮
      handleReset(){
        this.searchForm.paperPagNo =  ''; //票据包号
        this.searchForm.ticketClass =  ''; //票据种类(AC01：银票、AC02：商票)
        this.searchForm.acceptor =  ''; //承兑人
        this.searchForm.ticketDateStart =  ''; //出票日期 起始日期
        this.searchForm.ticketDateEnd =  '';   //出票日期 结束日期
        this.searchForm.tradeAmtMin =  '';     //票据包金额 min
        this.searchForm.tradeAmtMax =  '';     //票据包金额 max
        this.searchForm.dueDateStart =  '';    //到期日期 起始日期
        this.searchForm.dueDateEnd =  ''    //到期日期 结束日期
	      this.getTableList()
      },
		  // 列表按钮
      async handleTableBtn(row){
        this.text = '发送验证码';
        this.isDisabled = true
        this.ticketId = row.ticketId; //登记申请id -- 用于验证短信
	      this.handlerTypeCode = row.handlerTypeCode; // 操作类型code -- 用于验证短信
	      this.handlerNodeCode = row.handlerNodeCode; // 节点状态code -- 用于验证短信
        //获取手机号
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile;
        this.messageDialogVisible = true
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
        this.$nextTick(() => {
          this.text = '发送验证码';
          this.isDisabled = true
        })
      },
		  // 短信验证提交
      handleSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            if (this.placeholder === '2'){
              console.log('2')
              // 提示承兑申请
              const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptApply/submit", this.$qs.stringify(submitParams));
              this.copyText = result.data.text;
              this.copyCode = result.data.handlerNo;
              this.messageDialogVisible = false;
              this.successDialogVisible = true
            }else if(this.placeholder === '3'){
              console.log('3')
              // 提示承兑撤回
              const {data: result} = await this.$auth.post("/cpiaoju-ticket/ticket/manage/mt/accept/cancel", this.$qs.stringify(submitParams));
              this.copyText = result.data.text;
              this.copyCode = result.data.handlerNo;
              this.messageDialogVisible = false;
              this.successDialogVisible = true
            } else if(this.placeholder === '4'){
              console.log('4')
              // 提示收票申请
	            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/takeApply/submit",this.$qs.stringify(submitParams));
              this.copyText = result.data.text;
              this.copyCode = result.data.handlerNo;
              this.messageDialogVisible = false;
              this.successDialogVisible = true
            }else {
              console.log('5')
              // 提示收票撤回
              const {data: result} = await this.$auth.post("/cpiaoju-ticket/ticket/manage/mt/take/cancel", this.$qs.stringify(submitParams));
              this.copyText = result.data.text;
              this.copyCode = result.data.handlerNo;
              this.messageDialogVisible = false;
              this.successDialogVisible = true
            }
          }else {
            this.$message.error(res.msg);
          }
        })
      },
			// 票面预览
			async handleFaceView(row){
				let params = {
					paperBagNo: row.paperBagNo,
					section: row.section
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
				console.log(res)
				if (res.data.type === "application/pdf"){
					const blob = res.data;
					let link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.href = window.URL.createObjectURL(blob);
					link.click()
					URL.revokeObjectURL(link.href);
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
			//票面下载
			async handleFaceDownload(row){
				let params = {
					paperBagNo: row.paperBagNo,
					section: row.section
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
				console.log(res)
				if (res.headers['content-disposition']){
					const blob = res.data;
					const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
					let link = document.createElement('a');
					let url = URL.createObjectURL(blob);
					link.href = url;
					link.download = fileName
					console.log(link)
					link.click()
					URL.revokeObjectURL(url)
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
      // 监听倒计时按钮
      delayButtonClick(value){
        this.isDisabled = value
      },
      // 监听pagesize改变
      handleSizeChange(newSize){
        this.searchForm.pageSize = newSize;
        this.getTableList()
      },
      // 监听pagenum改变
      handleCurrentChange(newPage){
        this.searchForm.pageNum = newPage;
        this.getTableList()
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功');
					// 获取列表数据
					this.getTableList();
          this.successDialogVisible = false;
          return false;
        },err => {
          this.$message.error('复制失败');
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
	.search-form{
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 100% !important;
		}
		.data-gap{
			display: inline-block;
			margin: 0 5px;
		}
		.special-form-item{
			/deep/ .el-form-item__content{
				display: flex;
			}
		}
		.el-select{
			width: 100%;
		}
		.btn-form-item{
			text-align: center;
			.el-button{
				width: 160px;
			}
		}
	}
	/*同意收票弹框*/
	/deep/ .agree-dialog{
		display: flex;
		flex-direction: column;
		align-items: center;
		h3{
			margin-top: 20px;
		}
		.copy-code{
			margin-top: 15px;
			h4{
				display: inline-block;
				color: #C70009;
			}
		}
		.el-button{
			margin: 30px 24px 0;
		}
	}
	.verify-code{
		width: 50%;
	}
	/*票面*/
	#printTest{
		width: 800px;
		height: 500px;
		.face-url{
			width: 100%;
			height: 100%;
		}
	}
</style>