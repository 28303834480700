<template>
	<el-button :type="type" :disabled="!isDisabled" @click="clickBtn">
		{{isDisabled ? text : `重新发送(${delayTime}s)`}}
	</el-button>
</template>

<script>
  export default {
    name: "DelayButton",
    props: {
      text: {
        type: String,
      },
		  type: {
        type: String,
			  default: 'primary'
		  },
      isDisabled: {
        type: Boolean,
        default: true
      },
	  },
	  data(){
      return {
        // canClickRun: this.isDisabled, // 控制显示的文本和按钮是否禁用
	      delayTime: 60, // 倒计时初始数
        timer: null
      }
	  },
    watch: {
      isDisabled(value){
        if (value){
          clearInterval(this.timer);
        }
      }
    },
	  methods: {
      async clickBtn(){
        clearInterval(this.timer);
        this.timer = null
        this.delayTime = 60
	      const {data: res} = await this.$auth.post("/cpiaoju-ticket/common/sendMsg")
        if(res.code !== 200) {
					this.$message.error(res.msg)
					return false;
				}
        // this.isDisabled = false;
				this.$emit('delayButtonClick', false)
				this.timer = setInterval(() => {
          console.log('@')
					this.delayTime -= 1;
					if (this.delayTime <= 0){
						clearInterval(this.timer);
						// this.isDisabled = true
            this.$emit('delayButtonClick', true)
						this.delayTime = 60
					}
				},1000)
        this.$message.success(res.msg);
      }
	  }
  }
</script>

<style scoped>
</style>